/* eslint-disable no-undef */
import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { PRODUCTION_PROJECT_IDS } from "../consts";
import { projectId } from "../firebase";
import { selectSelectedCourse } from "../redux/coursesSlice";
import { addBreadcrumb } from "../utils/errorHandlers";
export function useOtiService() {
  const userId = useSelector((state) => state.firebase.auth.uid);
  const course = useSelector(selectSelectedCourse);
  const [pendoInitialized, setPendoInitialized] = useState(false);
  const otiRef = useRef({ service: null, courseId: undefined });

  const isLocalhost = window.location.hostname === "localhost";
  const isProductionEnv = PRODUCTION_PROJECT_IDS.includes(projectId);

  useEffect(() => {
    const shouldInitializePendo = !isLocalhost && userId && course;

    if (
      !shouldInitializePendo ||
      typeof Oti === "undefined" ||
      typeof Oti.OtiService === "undefined"
    )
      return;

    if (!otiRef.current.service) {
      addBreadcrumb("useOtiService", "initing OTI");
      otiRef.current.service = new Oti.OtiService();
    }

    const pendoDetails = {
      visitor: { id: userId, courseId: course.id, role: course.course_role },
      account: { id: course.institution_id }
    };

    if (!pendoInitialized) {
      addBreadcrumb("useOtiService", "initing Pendo");

      otiRef.current.service.initialize({
        ot_key: "0193ba53-ca55-7a0a-bb63-3dc03a0caf59",
        prod_env: isProductionEnv,
        pendo_key: "27782fb6-7abe-48ac-7547-ae6aba153327",
        pendo_details: pendoDetails,
        pendo_visitor_url:
          "https://app.eu.pendo.io/api/v1/metadata/visitor/agent/value?create=true",
        pendo_dnp_url: `https://app.eu.pendo.io/api/v1/metadata/visitor/pendo/value/${userId}/donotprocess`,
        pendo_metadata_url: `https://app.eu.pendo.io/api/v1/metadata/visitor/custom/value/${userId}/APPID_targetingcookies`,
        request_headers: {
          "x-pendo-integration-key": "6f9eeb33-3b9f-4b9c-5938-8525d3e410bb.eu",
          "Content-Type": "application/json"
        },
        pendo_optimize_requests: false,
        zendesk_key: "94d6e12d-accd-409d-a5b6-4b6354a43e0b",
        zendesk_appName: "clarivate8549"
      });
      setPendoInitialized(true);
    } else if (course.id !== otiRef.current.courseId) {
      addBreadcrumb("useOtiService", "updateVisitorPayload");

      otiRef.current.service.updateVisitorPayload(
        [
          {
            visitorId: userId,
            values: {
              courseId: course.id,
              role: course.course_role
            }
          }
        ],
        {
          pendo_details: pendoDetails
        }
      );
    }
    otiRef.current.courseId = course.id;
  }, [userId, course, pendoInitialized, isLocalhost, isProductionEnv]);

  // Return the service ref if you need to access it elsewhere
  return otiRef.current;
}
