// Dependencies
import React, { useRef } from "react";
import clsx from "clsx";
import { useLocation } from "react-router";

// Components

// Redux
import { useSelector } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";
import { AppBar, Toolbar, Box } from "@mui/material";
import {
  selectIsSuggestions,
  selectTextDirection
} from "./redux/firestoreSelectors";
import AletheaBreadcrumbs from "./components/breadcrumbs/AletheaBreadcrumbs";
import FeedbackControls from "./components/Tasks/TaskFeedback/FeedbackControls";

// Styles
const useStyles = makeStyles((theme) => {
  return {
    readerToolbar: {
      display: "flex",
      flexFlow: "row",
      height: "56px",
      minHeight: "56px",
      position: "relative",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: "20px"
    },

    readerBar: {
      backgroundImage: "none",
      backgroundColor: "#2E7D32",
      position: "sticky",
      top: "0",
      zIndex: 0
    },
    blueBg: {
      backgroundColor: "#003C8F",
      backgroundImage: "none"
    },
    analyticsBg: {
      backgroundColor: "#003C8F",
      fontColor: "#000"
    },
    adminBg: {
      backgroundColor: "#003C8F"
    },
    headerActionsContainer: {
      display: "flex",
      flexFlow: "row",
      justifyContent: "flex-end"
    },
    headerControls: {
      display: "flex",
      flexFlow: "row",
      height: "56px",
      justifyContent: "space-between"
    },
    globalAction: {
      display: "inline-block",
      "& button:not(.excludeParent button)": {
        paddingInline: theme.spacing(2),
        marginInlineStart: theme.spacing(3),
        fontSize: "13px",
        fontWeight: "bold",
        letterSpacing: "1px",
        backgroundColor: "#ffffff",
        color: "#1565C0",
        "&:hover": {
          backgroundColor: "#E8E8E8",
          color: "#1565C0"
        },
        "&:disabled": {
          backgroundColor: theme.palette.action.disabledBackground,
          color: theme.palette.text.disabled
        }
      }
    },
    pdfNavButtons: {
      display: "flex",
      justifyContent: "center"
    }
  };
});

export default function TopBar() {
  // Hooks
  const classes = useStyles();
  const appBarRef = useRef(null);
  const headerMenuRef = useRef();

  const textDirection = useSelector((state) => selectTextDirection(state));
  const isSuggestions = useSelector((state) => selectIsSuggestions(state));

  const location = useLocation();
  const activeGrTaskId = useSelector((state) => state.tasks.activeGrTaskId);
  const taskId = useSelector((state) => state.router.location.query.taskId);

  const blueBreadcrumbs =
    location.pathname.includes("task") ||
    (location.pathname.includes("text") && (activeGrTaskId || taskId));
  const analyticsBreadCrumbs = location.pathname.includes("reports");
  const adminBreadCrumbs = location.pathname.includes("admin");

  return (
    <AppBar
      component="div"
      className={clsx(
        classes.readerBar,
        blueBreadcrumbs && classes.blueBg,
        analyticsBreadCrumbs && classes.analyticsBg,
        adminBreadCrumbs && classes.adminBg
      )}
      ref={appBarRef}
      elevation={1}
      dir={textDirection}>
      <Toolbar className={classes.readerToolbar}>
        <Box>
          <AletheaBreadcrumbs headerMenuRef={headerMenuRef} />
        </Box>
        <Box className={classes.pdfNavButtons} id="global-pdfControl-btn"></Box>
        <Box className={classes.headerControls}>
          <Box ref={headerMenuRef} className={classes.headerActionsContainer}>
            <Box
              id="global-headerToolBar-btn"
              className={classes.headerToolBar}
            />
            <Box
              id="global-thumbnails-btn"
              className={classes.globalThumbnails}
            />
            <Box id="global-comments-btn" className={classes.globalComments} />
            <Box id="global-annonymize-btn" className={classes.headerToolBar} />
            <Box
              id="global-suggestions-btn"
              className={isSuggestions && classes.globalSuggestions}
            />
            <Box id="global-action-btn" className={classes.globalAction} />
            <FeedbackControls />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
