// Pollyfills
import 'core-js/proposals/promise-with-resolvers'; // Needed for older Safari version support

import "./firebase";
import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import * as Sentry from "@sentry/react";
import {
  captureConsoleIntegration,
  breadcrumbsIntegration,
  replayIntegration,
  feedbackIntegration,
  replayCanvasIntegration
} from "@sentry/browser";
import AppWrapper from "./AppWrapper.jsx";
import * as serviceWorker from "./serviceWorker";
import store from "./redux/store";
import { Provider } from "react-redux";
import { environment } from "./firebase";
import { FirebaseAppProvider } from "reactfire";
import { firebaseConfig } from "./firebase";
import { shouldLogToErrorMonitor } from "./utils/errorHandlers";
import "./global"; // This should be the first import
import {
  createBrowserRouter,
  createRoutesFromElements,
  createRoutesFromChildren,
  Route,
  matchRoutes,
  useLocation,
  useNavigationType
} from "react-router";

import { RouterProvider } from "react-router/dom";
import App from "./App.jsx";
import { UserApp } from "./UserApp.jsx";
import Layout from "./Layout.jsx";
import Router from "./Router.jsx";
import { FirebaseAuthProvider } from "./FirebaseAuthProvider.jsx";

if (shouldLogToErrorMonitor()) {
  console.log("Initing sentry");
  Sentry.init({
    dsn: "https://b5eda7ef2d694c6099e41a8689b07d45@o1281458.ingest.sentry.io/6487416",

    integrations: [
      Sentry.reactRouterV7BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      captureConsoleIntegration({ levels: ["error"] }),
      breadcrumbsIntegration({
        console: true,
        dom: true,
        fetch: true,
        history: true,
        xhr: true
      }),
      replayIntegration({
        blockAllMedia: false,
        maskAllText: false,
        mask: [".sentry-mask"]
      }),
      feedbackIntegration({
        // Additional SDK configuration goes in here, for example:
        colorScheme: "dark",
        autoInject: false
      }),
      replayCanvasIntegration()
    ],
    // debug: isProduction,
    environment,
    release: process.env.REACT_APP_RELEASE_VERSION,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // We track whole of prod and stage
    replaysSessionSampleRate: 1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    tags: {
      branch: process.env.REACT_APP_RELEASE_SOURCE_BRANCH || "unknown"
    }
  });
}

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);

const container = document.getElementById("root");
const root = createRoot(container);

const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route path="/*" element={<AppWrapper />}>
      <Route path="*" element={<App />}>
        <Route path="*" element={<UserApp />}>
          <Route path="*" element={<Layout />}>
            <Route element={<Router />} />
          </Route>
        </Route>
      </Route>
    </Route>
  )
);

root.render(
  <Provider store={store}>
    <FirebaseAppProvider firebaseConfig={firebaseConfig.options}>
      <FirebaseAuthProvider>
        <RouterProvider router={router} />
      </FirebaseAuthProvider>
    </FirebaseAppProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
