import React, { useState, useEffect } from "react";
import { isEmulated, crossed } from "../../firebase";
import { useImpersonate } from "../../hooks";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Link,
  Typography
} from "@mui/material";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
  Code as CodeIcon,
  Terminal as TerminalIcon,
  Warning as WarningIcon
} from "@mui/icons-material";
import useDeviceDetect from "../../hooks/useDeviceDetect";

// Minimum supported browser versions
const MIN_BROWSER_VERSIONS = {
  chrome: 90,
  firefox: 90,
  safari: 14,
  edge: 90,
  opera: 76
};

// Polyfill for Promise.withResolvers
if (typeof Promise.withResolvers !== "function") {
  Promise.withResolvers = function () {
    let resolve, reject;
    const promise = new Promise((res, rej) => {
      resolve = res;
      reject = rej;
    });
    return { promise, resolve, reject };
  };
}

function AdminNotification() {
  const navigate = useNavigate();
  const { stopImpersonate } = useImpersonate();
  const originalAuth = useSelector((state) => state.user.original_auth);
  const device = useDeviceDetect();

  const [expand, setExpand] = useState(true);
  const [showOwnershipDialog, setShowOwnershipDialog] = useState(false);
  const [takingOwnershipConfirmed, setTakingOwnershipConfirmed] =
    useState(false);
  const [showBrowserWarning, setShowBrowserWarning] = useState(false);
  const [browserInfo, setBrowserInfo] = useState({ name: "", version: 0 });

  const isImpersonation = originalAuth && originalAuth !== -1;

  useEffect(() => {
    // Detect browser and version
    const detectBrowser = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      let browserName = "";
      let browserVersion = 0;

      if (userAgent.indexOf("edge") > -1 || userAgent.indexOf("edg") > -1) {
        browserName = "edge";
        browserVersion = parseInt(
          userAgent.match(/edge?\/([0-9]+)/)?.[1] ||
            userAgent.match(/edg\/([0-9]+)/)?.[1] ||
            "0"
        );
      } else if (userAgent.indexOf("chrome") > -1) {
        browserName = "chrome";
        browserVersion = parseInt(
          userAgent.match(/chrome\/([0-9]+)/)?.[1] || "0"
        );
      } else if (userAgent.indexOf("firefox") > -1) {
        browserName = "firefox";
        browserVersion = parseInt(
          userAgent.match(/firefox\/([0-9]+)/)?.[1] || "0"
        );
      } else if (userAgent.indexOf("safari") > -1) {
        browserName = "safari";
        browserVersion = parseInt(
          userAgent.match(/version\/([0-9]+)/)?.[1] || "0"
        );
      } else if (
        userAgent.indexOf("opera") > -1 ||
        userAgent.indexOf("opr") > -1
      ) {
        browserName = "opera";
        browserVersion = parseInt(
          userAgent.match(/opera\/([0-9]+)/)?.[1] ||
            userAgent.match(/opr\/([0-9]+)/)?.[1] ||
            "0"
        );
      }

      return { name: browserName, version: browserVersion };
    };

    const browser = detectBrowser();
    setBrowserInfo(browser);

    // Check if browser is supported
    if (browser.name && MIN_BROWSER_VERSIONS[browser.name]) {
      setShowBrowserWarning(
        browser.version < MIN_BROWSER_VERSIONS[browser.name]
      );
    } else if (browser.name) {
      // Unknown browser but it has a name, don't show warning
      setShowBrowserWarning(false);
    } else {
      // Completely unknown browser, show warning to be safe
      setShowBrowserWarning(true);
    }

    // Don't show warning if user already dismissed it
    if (localStorage.getItem("browserWarningDismissed") === "true") {
      setShowBrowserWarning(false);
    }
  }, []);

  const handleTakeOwnership = () => {
    localStorage.removeItem("impersonate.originalUser");
    window.location.reload();
  };

  const handleStopEmulating = () => {
    const url = new URL(window.location.href);
    url.searchParams.set("emulated", false);
    navigate(url.pathname + url.search + url.hash);
    window.location.reload();
  };

  const handleDismissBrowserWarning = () => {
    setShowBrowserWarning(false);
    localStorage.setItem("browserWarningDismissed", "true");
  };

  const getNotificationContent = () => {
    if (isImpersonation) {
      return expand ? (
        "Impersonation"
      ) : (
        <SupervisedUserCircleIcon color="inherit" />
      );
    }
    if (isEmulated) {
      return expand ? "Emulated" : <CodeIcon color="inherit" />;
    }
    if (crossed) {
      return expand ? (
        `Crossed with ${crossed.charAt(0).toUpperCase() + crossed.slice(1)} backend`
      ) : (
        <TerminalIcon color="inherit" />
      );
    }
    return null;
  };

  const isUnsupportedDevice = () => {
    return device.isMobile || device.isIPhone || device.isAndroid;
  };

  const getBrowserUpdateLink = () => {
    switch (browserInfo.name) {
      case "chrome":
        return "https://www.google.com/chrome/";
      case "firefox":
        return "https://www.mozilla.org/firefox/new/";
      case "safari":
        return "https://support.apple.com/downloads/safari";
      case "edge":
        return "https://www.microsoft.com/edge";
      case "opera":
        return "https://www.opera.com/download";
      default:
        return "https://browsehappy.com/";
    }
  };

  const renderOwnershipDialog = () => (
    <Dialog
      open={showOwnershipDialog}
      onClose={() => {
        setShowOwnershipDialog(false);
        setTakingOwnershipConfirmed(false);
      }}
      aria-labelledby="ownership-dialog-title"
      maxWidth="sm"
      fullWidth>
      <DialogTitle id="ownership-dialog-title">Delete confirmation</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to do this?</Typography>
        <FormGroup>
          <FormControlLabel
            required
            control={
              <Checkbox
                checked={takingOwnershipConfirmed}
                onChange={(e) => setTakingOwnershipConfirmed(e.target.checked)}
              />
            }
            label="I'm sure"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setShowOwnershipDialog(false);
            setTakingOwnershipConfirmed(false);
          }}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            setShowOwnershipDialog(false);
            setTakingOwnershipConfirmed(false);
            handleTakeOwnership();
          }}
          color="primary"
          disabled={!takingOwnershipConfirmed}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderActionButtons = (severity) => (
    <Box sx={{ display: "flex", alignItems: expand ? "center" : "flex-end" }}>
      {severity === "info" && isImpersonation && expand && (
        <>
          <Link
            variant="body2"
            color="inherit"
            underline="none"
            component="button"
            onClick={stopImpersonate}
            sx={{ marginRight: "16px" }}>
            Stop impersonation
          </Link>
          <Link
            variant="body2"
            color="inherit"
            underline="none"
            component="button"
            onClick={() => setShowOwnershipDialog(true)}
            sx={{ marginRight: "8px" }}>
            Take ownership
          </Link>
        </>
      )}
      {severity === "info" && isEmulated && expand && (
        <Link
          variant="body2"
          color="inherit"
          underline="none"
          component="button"
          onClick={handleStopEmulating}
          sx={{ marginRight: "16px" }}>
          Stop emulating
        </Link>
      )}
      {severity === "warning" && expand && (
        <Button
          color="inherit"
          size="small"
          onClick={handleDismissBrowserWarning}
          sx={{ mr: 1 }}>
          Dismiss
        </Button>
      )}
      <IconButton
        onClick={() => setExpand(!expand)}
        size="small"
        color="inherit"
        aria-label={expand ? "Show less" : "Show more"}>
        {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>
    </Box>
  );

  const iconProp = expand ? {} : { icon: false };

  const renderNotificationAlert = (
    content,
    severity = "info",
    zIndex = 10000
  ) => (
    <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <Box
        sx={{
          margin: "8px",
          position: "absolute",
          zIndex: zIndex,
          width: expand ? "600px" : "100px",
          top: expand ? "0px" : "-16px"
        }}>
        <Alert
          severity={severity}
          {...iconProp}
          sx={{
            "& .MuiAlert-action": { paddingTop: "0" },
            "& .MuiAlert-message": { paddingBottom: expand ? "8px" : "0" },
            display: "flex",
            alignItems: expand ? "center" : "flex-end"
          }}
          action={renderActionButtons(severity)}>
          {content}
        </Alert>
      </Box>
    </Box>
  );

  // Render browser warning banner (highest priority)
  if (showBrowserWarning) {
    return (
      <>
        {renderNotificationAlert(
          <>
            {expand ? (
              <Typography variant="body2">
                Your browser ({browserInfo.name} {browserInfo.version}) may not
                be fully supported. For the best experience, please{" "}
                <Link
                  href={getBrowserUpdateLink()}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="inherit"
                  underline="always">
                  update your browser
                </Link>
                .
              </Typography>
            ) : (
              <WarningIcon color="inherit" />
            )}
          </>,
          "warning",
          10001 // Higher zIndex to show on top
        )}
        {/* Also render other notifications if they exist */}
        {(isEmulated || crossed || isImpersonation) &&
          renderNotificationAlert(getNotificationContent(), "info", 10000)}
        {showOwnershipDialog && renderOwnershipDialog()}
      </>
    );
  }

  // If we have a notification condition, show that
  if (isEmulated || crossed || isImpersonation) {
    const content = getNotificationContent();
    return (
      <>
        {renderNotificationAlert(content)}
        {showOwnershipDialog && renderOwnershipDialog()}
      </>
    );
  }

  // If no notification conditions and device is supported, return null
  if (!isUnsupportedDevice()) {
    return null;
  }

  // Show unsupported device message
  return renderNotificationAlert(
    expand
      ? `Alethea is designed for desktop use. Kindly access it from a computer.`
      : null
  );
}

export default AdminNotification;
