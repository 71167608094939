import React, { useState, useEffect } from "react";

import { FormattedMessage } from "react-intl";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions
} from "@mui/material";
import { useSelector } from "react-redux";

export default function VersionUpdateDialog() {
  const [shouldShow, setShouldShow] = useState(false);

  const remoteVersion = useSelector(
    (state) => state.firestore?.data?.defaultConfig?.VERSION
  );
  const localVersion =
    process.env.REACT_APP_BUILD_NUM || Number.MAX_SAFE_INTEGER.toString();

  useEffect(() => {
    try {
      // Get local version from environment variable

      // Check if we've shown the dialog in the last 24 hours
      const lastShownTimestamp = localStorage.getItem("versionDialogLastShown");

      const currentTime = new Date().getTime();

      // If we've shown it in the last 24 hours, don't show it again
      if (
        lastShownTimestamp &&
        currentTime - parseInt(lastShownTimestamp) < 24 * 60 * 60 * 1000
      ) {
        return;
      }

      // Compare versions (assumes numeric versions)
      if (
        localVersion &&
        remoteVersion &&
        parseInt(localVersion) < parseInt(remoteVersion)
      ) {
        setShouldShow(true);

        // Save the current timestamp to localStorage
        localStorage.setItem("versionDialogLastShown", currentTime.toString());
      }
    } catch (error) {
      console.error("Error checking version:", error);
    }
  }, [localVersion, remoteVersion]);

  const handleClose = () => {
    setShouldShow(false);
  };

  return (
    <Dialog open={shouldShow} onClose={handleClose}>
      <DialogContent>
        <Typography>
          <FormattedMessage
            id="upgrade.msg"
            defaultMessage="A new Alethea version is available, click 'Reload' to update"
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="version-reload-button"
          aria-label="Reload"
          sx={{
            backgroundColor: "#168fee",
            color: "#ffffff",
            textTransform: "none",
            "&:hover": {
              background: "#1172be"
            }
          }}
          onClick={() => {
            window.location.reload(true);
          }}>
          <FormattedMessage id="upgrade.reload" defaultMessage="Reload" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
