import React, { Suspense, useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router";
import * as Sentry from "@sentry/react";

// Lazy loaded components
const Reader = React.lazy(() => import("./components/reader/Reader"));
const Library = React.lazy(() => import("./components/library/Library"));
const TasksRoutes = React.lazy(() => import("./routes/TasksRoutes"));
const TaskRoutes = React.lazy(() => import("./routes/TaskRoutes"));
const AdminRoutes = React.lazy(() => import("./routes/AdminRoutes"));
const ReportsRoutes = React.lazy(() => import("./routes/ReportsRoutes"));
import ContactUs from "./components/contactUs/ContactUs";
import Notifications from "./Notifications";
import ProfilePage from "./components/ProfilePage/ProfilePage";
import Logout from "./components/auth/Logout";
import { PangeaSpinner } from "./components/SharedComponents";
import useUserClaim from "./hooks/useUserClaim";
import MFASetup from "./components/auth/mfa/MFASetup";

const SentryRoutes = Sentry.withSentryReactRouterV7Routing(Routes);

// Reusable components
const LazyRoute = ({ component: Component, ...props }) => (
  <Suspense fallback={<PangeaSpinner />}>
    <Component {...props} />
  </Suspense>
);

export function ScrollToTopOnMount() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return null;
}

const ProtectedRoute = ({ children, requiredRoles = [] }) => {
  const { claim: userClaim, isLoading } = useUserClaim();

  if (isLoading) {
    return <PangeaSpinner />;
  }

  if (!requiredRoles.includes(userClaim)) {
    return <Navigate to="/" replace />;
  }

  return children;
};

// Route configurations
const publicRoutes = [
  { path: "/", element: <Navigate to="/tasks" replace /> },
  { path: "/logout", element: <Logout /> },
  { path: "/contactUs", element: <ContactUs /> },
  { path: "/notifications", element: <Notifications /> },
  { path: "/profile", element: <ProfilePage /> }
];

const lazyRoutes = [
  {
    path: "/tasks/*",
    component: TasksRoutes
  },
  {
    path: "/task/*",
    component: TaskRoutes
  },
  {
    path: "/library",
    component: Library
  },
  {
    path: "/reader",
    component: Reader,
    props: { grSettings: { showSelfFeedback: true } }
  },
  {
    path: "/reports/*",
    component: ReportsRoutes
  },
  {
    path: "/mfa-setup",
    component: MFASetup
  }
];

const protectedRoutes = [
  {
    path: "/admin/*",
    component: AdminRoutes,
    requiredRoles: ["admin", "dataViewer"]
  },
  {
    path: "/test/*",
    component: () => (
      <SentryRoutes>
        <Route path="crash" element={<ErrorComponent />} />
      </SentryRoutes>
    ),
    requiredRoles: ["admin", "dataViewer"]
  }
];

export default function Router() {
  return (
    <SentryRoutes>
      {/* Public Routes */}
      {publicRoutes.map(({ path, element }) => (
        <Route key={path} path={path} element={element} />
      ))}

      {/* Lazy Loaded Routes */}
      {lazyRoutes.map(({ path, component: Component, props = {} }) => (
        <Route
          key={path}
          path={path}
          element={<LazyRoute component={Component} {...props} />}
        />
      ))}

      {/* Protected Routes */}
      {protectedRoutes.map(({ path, component: Component, requiredRoles }) => (
        <Route
          key={path}
          path={path}
          element={
            <ProtectedRoute requiredRoles={requiredRoles}>
              <LazyRoute component={Component} />
            </ProtectedRoute>
          }
        />
      ))}
    </SentryRoutes>
  );
}

// Test error component
export function ErrorComponent() {
  throw new Error("Test error");
}
